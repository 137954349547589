package com.yektasarioglu.personal.web.fragment.composable

import androidx.compose.runtime.remember
import com.yektasarioglu.personal.web.common.logger.Log
import com.yektasarioglu.personal.web.gtag
import com.yektasarioglu.personal.web.ui.component.CheckBoxConsent
import com.yektasarioglu.personal.web.ui.component.CookieConsentBanner
import kotlinx.browser.document
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.renderComposable
import kotlin.js.json

const val TargetID = "cookie-consent-container"

fun cookieConsentEntrypointComposable() {
    val targetElement = document.getElementById("target")
    val cookieConsentElement = document.createElement("div")
    cookieConsentElement.id = TargetID
    targetElement?.appendChild(cookieConsentElement)

    renderComposable(rootElementId = TargetID) {
        val initialAllowedConsentOptions = ConsentOption.values().filter { it.isInitiallyChecked }.toTypedArray()
        val allowedConsentOptions = remember { mutableSetOf(*initialAllowedConsentOptions) }

        CookieConsentBanner(
            checkBoxList = ConsentOption.entries.map {
                CheckBoxConsent(
                    name = it.name,
                    isInitiallyChecked = it.isInitiallyChecked,
                    onChecked = { isChecked ->
                        Log.d("Before - allowedConsentOptions is $allowedConsentOptions")

                        if (isChecked)
                            allowedConsentOptions.add(ConsentOption.valueOf(it.name))
                        else
                            allowedConsentOptions.remove(ConsentOption.valueOf(it.name))

                        Log.d("After - allowedConsentOptions is $allowedConsentOptions")
                    })
            },
            onAcceptAllClicked = {
                acceptedConsentOptions(ConsentOption.values().toSet())
            },
            onAcceptSelectionClicked = {
                acceptedConsentOptions(allowedConsentOptions)
            },
            onRejectAllClicked = {
                acceptedConsentOptions(setOf())
            }
        )
    }
}

private fun acceptedConsentOptions(allowedConsentOptions: Set<ConsentOption>) {
    val cookieConsentElement = document.getElementById(TargetID)

    val consentMode = json(
        "ad_storage" to if (allowedConsentOptions.contains(ConsentOption.Marketing)) "granted" else "denied",
        "analytics_storage" to if (allowedConsentOptions.contains(ConsentOption.Analytics)) "granted" else "denied",
        "functionality_storage" to if (allowedConsentOptions.contains(ConsentOption.Necessary)) "granted" else "denied",
        "personalization" to if (allowedConsentOptions.contains(ConsentOption.Preferences)) "granted" else "denied",
        "personalization_storage" to if (allowedConsentOptions.contains(ConsentOption.Preferences)) "granted" else "denied",
        "security_storage" to if (allowedConsentOptions.contains(ConsentOption.Necessary)) "granted" else "denied"
    )

    println("AppAction.CommonAction.AcceptedConsentOptions - consentMode is $consentMode")
    //Log.d("AppAction.CommonAction.AcceptedConsentOptions - consentMode is $consentMode")

    localStorage.setItem("consentMode", JSON.stringify(consentMode))
    gtag("consent", "update", consentMode)

    cookieConsentElement?.remove()
}

enum class ConsentOption(val isInitiallyChecked: Boolean) {
    Analytics(true), Marketing(false), Necessary(true), Preferences(true)
}