package com.yektasarioglu.personal.web.ui.compose.html

import androidx.compose.runtime.Composable
import kotlinx.browser.document
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.ElementBuilder
import org.jetbrains.compose.web.dom.TagElement
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement

private val U: ElementBuilder<HTMLElement> = object : ElementBuilder<HTMLElement> {
    private val el: Element by lazy { document.createElement("u") }
    @Suppress("UNCHECKED_CAST")
    override fun create(): HTMLElement = el.cloneNode() as HTMLElement
}
@Composable
fun U(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null
) = TagElement(elementBuilder = U, applyAttrs = attrs, content = content)