package com.yektasarioglu.personal.web.ui.designsystem.foundation

import com.yektasarioglu.personal.web.common.logger.Log
import com.yektasarioglu.personal.web.ui.css.ComputedStyle
import com.yektasarioglu.personal.web.ui.designsystem.cssVariable
import com.yektasarioglu.personal.web.ui.designsystem.hexToRgb
import com.yektasarioglu.personal.web.ui.designsystem.invert
import com.yektasarioglu.personal.web.ui.designsystem.rgbToHex
import kotlin.math.abs

// https://maketintsandshades.com/about#example-calculation

typealias ColorValue = String

// disabledColor
data class Colors(
    val primaryColor: Color,
    val secondaryColor: Color,
    val tertiaryColor: Color,
    val linkColor: Color, // interactive
    val hoverLinkColor: Color,
    val successColor: Color,
    val failureColor: Color,
    val textColor: Color,
    val subtleTextColor: Color,
    val accentColor: Color,
    val backgroundColor: Color,
    val blockquoteBackgroundColor: Color,
    val thumbScrollBarColor: Color
): DesignElement<Color> {
    val brandColors = listOf(
        primaryColor,
        secondaryColor,
        tertiaryColor
    )
    val stateColors = listOf(successColor, failureColor)
    val linkColors = listOf(linkColor, hoverLinkColor)
    val textColors = listOf(textColor, subtleTextColor)
    val miscellaneousColors = listOf(accentColor, backgroundColor, blockquoteBackgroundColor, thumbScrollBarColor)

    override val all = brandColors + linkColors + stateColors + textColors + miscellaneousColors
    override val sections = listOf(
        "Brand colors" to brandColors,
        "State colors" to stateColors,
        "Link colors" to linkColors,
        "Text colors" to textColors,
        "Miscellaneous colors" to miscellaneousColors
    )
}

data class Color(
    val name: String,
    val value: ColorValue
) {

    /*operator fun plus(other: Color): List<Color> {
        return listOf(this, other)
    }

    operator fun plus(other: List<Color>): List<Color> {
        return other + this
    }*/
}

open class RGB(
    open val red: Short,
    open val green: Short,
    open val blue: Short
) {
    override fun toString(): String = "RGB(red: $red, green: $green, blue: $blue)"
}

data class RGBA(
    override val red: Short,
    override val green: Short,
    override val blue: Short,
    val alpha: Short
): RGB(red, green, blue) {
    override fun toString(): String = "RGB(red: $red, green: $green, blue: $blue, alpha: $alpha)"
}

fun Triple<Int, Int, Int>.toRGB() = RGB(this.first.toShort(), this.second.toShort(), this.third.toShort())
fun Triple<Short, Short, Short>.toRGB() = RGB(this.first, this.second, this.third)


// TODO: Write tests for it
val ColorValue.inverted: String
    //get() = invertHexString(hexString = this)
    get() {
        Log.d("inverted - this is $this")
        val rgb = hexToRgb(this) ?: Triple(0, 0, 0)
        val invertedRGB = invert(red = rgb.first, green = rgb.second, blue = rgb.third)
        Log.d("inverted - invertedRGB is $invertedRGB")

        val invertedHex = rgbToHex(red = invertedRGB.first, green = invertedRGB.second, blue = invertedRGB.third)
        //val invertedHex =  invertHexString(hexString = )
        Log.d("inverted - invertedHex is $invertedHex")
        //return "#$invertedHex"
        return invertedHex ?: ""
    }

// TODO: Write tests for it
fun ColorValue.tint(percent: Double = 0.1) : String {
    val rgb = hexToRgb(this)?.toRGB() ?: return this
    Log.d("tint - rgb is $rgb")
    val tintedRGB = RGB(
        red = (rgb.red + ((255 - rgb.red) * percent)).toInt().toShort(),
        green = (rgb.green + ((255 - rgb.green) * percent)).toInt().toShort(),
        blue = (rgb.blue + ((255 - rgb.blue) * percent)).toInt().toShort()
    )
    Log.d("tint - newRGB is $tintedRGB")
    val tintedHex = rgbToHex(red = tintedRGB.red.toInt(), green = tintedRGB.green.toInt(), blue = tintedRGB.blue.toInt()) ?: ""
    Log.d("tint - final hex is $tintedHex")
    return tintedHex
}

// TODO: Write tests for it
fun ColorValue.shade(percent: Double = 0.1) : String {
    val rgb = hexToRgb(this)?.toRGB() ?: return this
    Log.d("shade - rgb is $rgb")
    val shadedRGB = RGB(
        red = abs(rgb.red * (1 - percent)).toInt().toShort(),
        green = abs(rgb.green * (1 - percent)).toInt().toShort(),
        blue = abs(rgb.blue * (1 - percent)).toInt().toShort()
    )
    Log.d("shade - newRGB is $shadedRGB")
    val shadedHex = rgbToHex(red = shadedRGB.red.toInt(), green = shadedRGB.green.toInt(), blue = shadedRGB.blue.toInt()) ?: ""
    Log.d("shade - final hex is $shadedHex")
    return shadedHex
}

// TODO: Write tests for it
// FORMULA
// Luminance (standard, objective): (0.2126*R) + (0.7152*G) + (0.0722*B)
fun ColorValue.luminance() : Double {
    val rgb = hexToRgb(this)?.toRGB() ?: return .0
    return (0.2126 * rgb.red) + (0.7152 * rgb.green) + (0.0722 * rgb.blue)
}

// TODO: Write tests for it
// (luminanceValueInBase16 * 100) / 255
// For white, you get 100%
fun ColorValue.luminancePercent() : Double = (this.luminance() * 100) / 255

val Color.cssVariableValue
    get() = cssVariable(this.value)

// It returns either hex, rgb, rgba, hsl, hsla or color value
val Color.propertyValue: String?
    get() = ComputedStyle.getRootPropertyValue(this.value)