package com.yektasarioglu.personal.web.common.logger

import co.touchlab.kermit.BaseLogger
import co.touchlab.kermit.Severity
import co.touchlab.kermit.loggerConfigInit
import co.touchlab.kermit.platformLogWriter

abstract class Logger : BaseLogger(loggerConfigInit(platformLogWriter())) {

    //private val isDebugMode: Boolean = process.env.IS_DEBUG.toBoolean()
    abstract val isDebugMode: Boolean

    private var tag: String = ""

    open fun i(message: String) {
        if (isDebugMode) {
            log(Severity.Info, tag, null, message)
        }
    }

    open fun d(message: String) {
        if (isDebugMode) {
            log(Severity.Debug, tag, null, message)
        }
    }

    open fun e(message: String, throwable: Throwable?) {
        if (isDebugMode) {
            log(Severity.Error, tag, throwable, message)
        }
    }

    open fun setTag(tag: String) {
        this.tag = tag
    }
}

//expect val Any.Log: BaseLogger
expect val Any.Log: Logger