package com.yektasarioglu.personal.web.utils

private val isDebug = false

private val debugColors = listOf(
    "bg-orange-900",
    "bg-blue-800",
    "bg-green-600",
    "bg-orange-600",
    "bg-orange-300",
    "bg-orange-100",
    "bg-yellow-400",
    "bg-lime-400",
    "bg-sky-900",
    "bg-sky-700",
    "bg-sky-500",
    "bg-sky-300",
    "bg-emerald-700",
    "bg-emerald-500",
    "bg-emerald-300",
    "bg-purple-400"
)
fun getRandomDebugColor() = if (isDebug) {
    debugColors.random()
} else {
    ""
}

fun cssClasses(vararg classes: String): List<String> {
    return if (isDebug) (classes + getRandomDebugColor()).toList()
    else classes.toList()
}