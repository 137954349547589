package com.yektasarioglu.personal.web.behaviour

import com.yektasarioglu.personal.web.common.extensions.tokenize
import com.yektasarioglu.personal.web.common.logger.Log
import com.yektasarioglu.personal.web.utils.asElementList
import com.yektasarioglu.personal.web.utils.asHTMLElementList
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.events.EventListener

interface IHighlightActiveTOCHeader {
    fun enable()
    fun disable()
}
class HighlightActiveTOCHeader : IHighlightActiveTOCHeader {

    private var listener: EventListener? = null
    var activeHeaderId: String = ""

    override fun enable() {
        listener = EventListener {
            val headers = document.querySelectorAll("#post-content h2, #post-content h3")
            val navLi = document.querySelectorAll("#table-of-content ul li")

            headers.asHTMLElementList().forEach { header ->
                val headerDomRect = header.getBoundingClientRect()
                //val headerTop = headerDomRect.top
                val headerTop = header.offsetTop
                val headerHeight = headerDomRect.height
                Log.d("highlightActiveTableOfContentHeader() - headerTop is $headerTop")
                Log.d("highlightActiveTableOfContentHeader() - window.pageYOffset is ${window.pageYOffset}")

                val triggerY = headerTop - headerHeight / 3
                Log.d("highlightActiveTableOfContentHeader() - headerTopVision is $triggerY")

                if (window.pageYOffset >= triggerY) {
                    activeHeaderId = header.id
                    Log.d("highlightActiveTableOfContentHeader() - current is $activeHeaderId")
                }
            }

            navLi.asElementList().forEach { li ->
                Log.d("highlightActiveTableOfContentHeader() - li.textContent is ${li.textContent}")

                li.classList.remove("active")

                if (li.textContent?.tokenize() == activeHeaderId) {
                    li.classList.add("active")
                    Log.d("highlightActiveTableOfContentHeader() - ${li.id} is active")
                }
            }
        }

        window.addEventListener("scroll", listener)
    }

    override fun disable() {
        window.removeEventListener("scroll", listener)
    }
}