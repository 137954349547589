package com.yektasarioglu.personal.web.behaviour

import com.yektasarioglu.personal.web.common.logger.Log
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.w3c.dom.events.EventListener

interface IDynamicHeader {
    fun enable(id: String)
    fun disable(id: String)
    fun stopScrollCheck()
}

class DynamicHeader : IDynamicHeader {

    private var listener: EventListener? = null

    enum class ScrollDirection {
        Initial, ScrollUp, ScrollDown
    }

    override fun stopScrollCheck() {
        window.removeEventListener("scroll", listener)
    }

    override fun enable(id: String) {
        val navHeaderHeight = 100
        var previousScroll = window.scrollY
        var currentScroll = 0.0
        var isInitialScroll = true
        var currentDirection = ScrollDirection.Initial
        var previousDirection = ScrollDirection.Initial

        val header = document.getElementById(id) ?: return

        val toggleHeader = { direction: ScrollDirection, scroll: Double ->
            Log.d("direction is $direction")
            Log.d("curScroll is $scroll")

            if (direction == ScrollDirection.ScrollUp && scroll > navHeaderHeight)
                header.classList.remove("hide")
            else if (direction == ScrollDirection.ScrollDown) {
                if (!header.hasClass("sticky"))
                    header.addClass("sticky")
                else
                    header.classList.add("hide")
            }

            previousDirection = direction
        }

        listener = EventListener {
            currentScroll = window.scrollY

            if (currentScroll > previousScroll) {
                //scrolled up
                currentDirection = ScrollDirection.ScrollDown
                Log.d("User has scrolled down!!")
            } else if (currentScroll < previousScroll) {
                //scrolled down
                currentDirection = ScrollDirection.ScrollUp
                Log.d("User has scrolled up!!")
            }

            if (currentDirection != previousDirection) {
                Log.d("New direction has detected!!")
                toggleHeader(currentDirection, currentScroll)
            } else if (isInitialScroll) {
                isInitialScroll = false
                toggleHeader(currentDirection, currentScroll)
            }

            previousScroll = currentScroll
        }

        //postEventListeners.add(checkScroll)

        window.addEventListener("scroll", listener)
    }

    override fun disable(id: String) {
        val header = document.getElementById(id) ?: return
        header.removeClass("sticky")
    }


    /*private fun hideDynamicHeader(id: String) {
        val header = document.getElementById(id) ?: return
        header.removeClass("hide")
    }*/
}