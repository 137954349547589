package com.yektasarioglu.personal.web.ui.designsystem.foundation

data class TypographyElement(
    val name: String,
    val value: String
)

data class Typography(
    val header1: TypographyElement,
    val header2: TypographyElement,
    val header3: TypographyElement,
    val subtitle: TypographyElement,
    val body: TypographyElement,
    val bold: TypographyElement,
    val small: TypographyElement,
    val link: TypographyElement,
    val linkHover: TypographyElement,
    val button: TypographyElement
) : DesignElement<TypographyElement> {
    val headers = listOf(header1, header2, header3, subtitle)
    val bodies = listOf(body, bold, small)
    val links = listOf(link, linkHover)
    val misc = listOf(button)

    override val all = headers + bodies + links + misc
    override val sections = listOf(
        "Headers" to headers,
        "Bodies" to bodies,
        "Links" to links,
        "Misc" to misc
    )
}

// TODO: Yuck, find another way to use this
fun TypographyElement.getCSSEquivalent() : String {
    return when {
        this.value.contains("header1") -> "h1"
        this.value.contains("header2") -> "h2"
        this.value.contains("header3") -> "h3"
        this.value.contains("subtitle") -> "b"
        this.value.contains("body") -> "p"
        this.value.contains("bold") -> "b"
        this.value.contains("small") -> "pre"
        this.value.contains("link") -> "a"
        this.value.contains("link_hover") -> "a:hover"
        this.value.contains("button") -> "button"
        else -> ""
    }
}