package com.yektasarioglu.personal.web.ui.compose.tailwind.foundation.layout

import androidx.compose.runtime.Composable
import com.yektasarioglu.personal.web.utils.cssClasses
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement

@Composable
fun Column(
    id: String = "",
    verticalArrangement: Arrangement.HorizontalOrVertical = Arrangement.spacedBy(0.0),
    classSelectors: List<String> = listOf(),
    content: ContentBuilder<HTMLDivElement>? = null
) {
    Div(
        attrs = {
            if (id.isNotEmpty()) {
                id(id)
            }
            classes(cssClasses("flex", "flex-col") + classSelectors)
            style {
                property("column-gap", verticalArrangement.spacing)
            }
        },
        content = content
    )
}

@Composable
fun FlexColumn(
    id: String = "",
    classSelectors: List<String> = listOf(),
    composables: List<@Composable () -> Unit> = listOf()
) {
    Div(attrs = {
        if (id.isNotEmpty()) {
            id(id)
        }
        classes(cssClasses("flex", "flex-col") + classSelectors)
    }) {
        composables.forEachIndexed { index, composable ->
            composable()
        }
    }
}