package com.yektasarioglu.personal.web.ui.designsystem

import com.yektasarioglu.personal.web.common.extensions.addSuffix
import com.yektasarioglu.personal.web.common.logger.Log
import com.yektasarioglu.personal.web.common.logger.JSLogger
import kotlin.math.abs

// Encapsulates given variable name into var()
internal fun cssVariable(name: String) = "var($name)"

internal fun cssClass(name: String) = if (name.startsWith('.')) name else ".$name"

val String.cssClass
    get() = cssClass(this)

//fun invertHexString(hexString: String): String {
//    val hasOmittedHexString = hexString.removePrefix("#")
//
//    val result = StringBuilder()
//    for (i in 0.. (hasOmittedHexString.length - 2) step 2) {
//        result.append(StringBuilder(hasOmittedHexString.substring(i, i + 2)).reverse())
//    }
//
//    return result.toString()
//}

fun invertHexString(hexString: String): String {
    val hexChars = hexString.run {
        var shadowThis: String = this
        Log.d("invertHexString - shadowThis -> $shadowThis")
        shadowThis = this.removePrefix("#")
        Log.d("invertHexString - removePrefix -> $shadowThis")

        if (shadowThis.length != 8) {
            val missingRGBAChannelNumber = 6 - shadowThis.length
            Log.d("invertHexString - missingRGBAChannelNumber -> $missingRGBAChannelNumber")
            repeat(times = missingRGBAChannelNumber) {
                Log.d("invertHexString - repeat: index -> $it")
                shadowThis = shadowThis.addSuffix(suffix = "F", allowDuplicateSuffix = false)
                Log.d("invertHexString - repeat -> shadowThis: $shadowThis")
            }
            //shadowThis = shadowThis.addPrefix("FF")
            Log.d("invertHexString - shadowThis -> $shadowThis")
        }
        return@run shadowThis
    }.toCharArray()

    for (i in hexChars.indices) {
        if (hexChars[i].isLetterOrDigit()) {
            hexChars[i] = when (hexChars[i].uppercaseChar()) {
                in '0'..'9' -> ('9' - (hexChars[i] - '0'))
                in 'a'..'f' -> ('f' - (hexChars[i] - 'a'))
                in 'A'..'F' -> ('F' - (hexChars[i] - 'A'))
                else -> hexChars[i]
            }
        }
    }

    //return hexChars.toString()
    return hexChars.concatToString()
}

fun hexToRgb(hex: String): Triple<Int, Int, Int>? {
    // Remove the '#' symbol if present and normalize the input to upper case
    var cleanHex = hex.trim().removePrefix("#").uppercase()

    repeat(times = 8 - hex.length) {
        cleanHex = cleanHex.addSuffix("F", allowDuplicateSuffix = false)
    }

    // Ensure that the cleaned hex string is a valid hexadecimal color code
    //if (!cleanHex.matches(Regex("[0-9A-F]{6}"))) {
//    if (!cleanHex.matches(Regex("[0-9A-F]"))) {
//        return null
//    }

    // Extract the red, green, and blue components
    val red = cleanHex.substring(0, 2).toInt(16)
    val green = cleanHex.substring(2, 4).toInt(16)
    val blue = cleanHex.substring(4, 6).toInt(16)

    return Triple(red, green, blue)
}

fun invert(red: Int, green: Int, blue: Int) : Triple<Int, Int, Int> {
    val newR = abs(red - 255)
    val newG = abs(green - 255)
    val newB = abs(blue - 255)
    JSLogger.d("invert(red: $red, blue: $blue, green: $green) -> invertedRed: $newR, invertedGreen: $newG, invertedBlue: $newB")
    return Triple(newR, newG, newB)
}

fun rgbToHex(red: Int, green: Int, blue: Int): String? {
    // Ensure that the RGB values are within the valid range (0 to 255)
    if (red !in 0..255 || green !in 0..255 || blue !in 0..255) {
        return null
    }

    // Convert each component to its two-digit hexadecimal form
    val redHex = red.toHex()
    val greenHex = green.toHex()
    val blueHex = blue.toHex()

    // Concatenate the components to form the hexadecimal representation
    return "#$redHex$greenHex$blueHex"
}

// Extension function to convert an integer to its two-digit hexadecimal form
private fun Int.toHex(): String {
    val hexChars = "0123456789ABCDEF"
    return "${hexChars[this / 16]}${hexChars[this % 16]}"
}
