package com.yektasarioglu.personal.web.ui.designsystem

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.compositionLocalOf
import com.yektasarioglu.personal.web.ui.designsystem.foundation.Color
import com.yektasarioglu.personal.web.ui.designsystem.foundation.Colors
import com.yektasarioglu.personal.web.ui.designsystem.foundation.Spacing
import com.yektasarioglu.personal.web.ui.designsystem.foundation.SpacingElement
import com.yektasarioglu.personal.web.ui.designsystem.foundation.Typography
import com.yektasarioglu.personal.web.ui.designsystem.foundation.TypographyElement

class Theme(val styleGuide: StyleGuide) {
    //abstract fun applyTheme()
}

val darkTheme = Theme(
    styleGuide = StyleGuide(
        typography = Typography(
            header1 = TypographyElement(name = "Header 1", value = "typography-header1_title"),
            header2 = TypographyElement(name = "Header 2", value = "typography-header2"),
            header3 = TypographyElement(name = "Header 3", value = "typography-header3"),
            subtitle = TypographyElement(name = "Subtitle", value = "typography-subtitle"),
            body = TypographyElement(name = "Body", value = "typography-body"),
            bold = TypographyElement(name = "Bold", value = "typography-bold"),
            small = TypographyElement(name = "Small", value = "typography-small"),
            link = TypographyElement(name = "Link", value = "typography-link"),
            linkHover = TypographyElement(name = "Link - Hover", value = "typography-link_hover"),
            button = TypographyElement(name = "Button", value = "typography-button")
        ),
        colors = Colors(
            primaryColor = Color(
                name = "Primary",
                value = "--color-primary"
            ),
            secondaryColor = Color(
                name = "Secondary",
                value = "--color-secondary"
            ),
            tertiaryColor = Color(
                name = "Tertiary",
                value = "--color-tertiary"
            ),
            linkColor = Color(
                name = "Link",
                value = "--color-link"
            ),
            hoverLinkColor = Color(
                name = "Hover Link",
                value = "--color-hover-link"
            ),
            successColor = Color(
                name = "Success",
                value = "--color-success"
            ),
            failureColor = Color(
                name = "Failure",
                value = "--color-failure"
            ),
            textColor = Color(
                name = "Text",
                value = "--color-text"
            ),
            subtleTextColor = Color(
                name = "Subtle Text",
                value = "--color-subtle-text"
            ),
            accentColor = Color(
                name = "Accent",
                value = "--color-accent"
            ),
            backgroundColor = Color(
                name = "Background",
                value = "--color-background"
            ),
            blockquoteBackgroundColor = Color(
                name = "Blockquote Background",
                value = "--color-blockquote-background"
            ),
            thumbScrollBarColor = Color(
                name = "Thumb Scroll Bar",
                value = "--color-thumb-scrollbar"
            )
        ),
        spacing = Spacing(
            xxsmall = SpacingElement(name = "XXSmall", value = "--space-2xsmall"),
            xsmall = SpacingElement(name = "XSmall", value = "--space-xsmall"),
            small = SpacingElement(name = "Small", value = "--space-small"),
            medium = SpacingElement(name = "Medium", value = "--space-medium"),
            large = SpacingElement(name = "Large", value = "--space-large"),
            xlarge = SpacingElement(name = "XLarge", value = "--space-xlarge"),
            xxlarge = SpacingElement(name = "XXLarge", value = "--space-2xlarge")
        )
    )
)

object AppTheme {
//    val colors: Colors
//        @Composable
//        @ReadOnlyComposable
//        get() = LocalColors.current

    val theme: Theme
        @Composable
        @ReadOnlyComposable
        get() = LocalTheme.current
}

val LocalTheme = compositionLocalOf { darkTheme }

@Composable
fun AppTheme(content: @Composable () -> Unit) {
    val theme = if (isSystemInDarkTheme()) darkTheme else darkTheme
    CompositionLocalProvider(LocalTheme provides theme) {
        content()
    }
}

fun isSystemInDarkTheme(): Boolean = true