@file:Suppress("JoinDeclarationAndAssignment", "MemberVisibilityCanBePrivate")

package com.yektasarioglu.personal.web.interop

import org.w3c.dom.HTMLElement
import kotlin.js.Json
import kotlin.js.json
import kotlin.properties.ReadWriteProperty
import kotlin.reflect.KProperty

//tippy(
//document.getElementById("#fuckYou") as HTMLElement,
//json(
//"content" to "DENEME"
//"delay" to arrayOf(delay, 0),
//"arrow" to "large",
//"zIndex" to PopupZIndex.getTopIndex(),
//disableBlurOptions
//)
//)

private var isCSSInitialized: Boolean = false

@JsModule("tippy.js")
@JsNonModule
external object TippyModule {
    fun default(element: HTMLElement, option: dynamic): dynamic
    fun default(element: String): dynamic
    fun default(element: String, options: dynamic): dynamic
}

fun tippy(element: HTMLElement, options: dynamic): dynamic = TippyModule.default(element, options)
fun tippy(element: String, options: dynamic): dynamic {
    //initializeCSS()
    return TippyModule.default(element, options)
}
fun tippy(element: String): dynamic {
    //initializeCSS()
    return TippyModule.default(element)
}

private fun initializeCSS() {
    if (!isCSSInitialized) {
        // https://discuss.kotlinlang.org/t/kotlin-js-a-style-from-the-npm-package/16759/3
        kotlinext.js.require("tippy.js/dist/tippy.css")
        isCSSInitialized = true
    }
}

val disableBlurOptions = "popperOptions" to json(
    "modifiers" to json(
        "computeStyle" to json(
            "gpuAcceleration" to false
        )
    )
)

class TippyOptions {
    val json = json()

    var interactive by JsonValue("interactive")
    var trigger by JsonValue("trigger")
    var theme by JsonValue("theme")
    var placement by JsonValue("placement")
    var arrow by JsonValue("arrow")
    var content by JsonValue("content")
    var zIndex by JsonValue("zIndex")
    var distance by JsonValue("distance")
    var maxWidth by JsonValue("maxWidth")
    var followCursor by JsonValue("followCursor")

    fun disableBlurOptions() {
        json["popperOptions"] = json(
            "modifiers" to json(
                "computeStyle" to json(
                    "gpuAcceleration" to false
                )
            )
        )

    }


    class JsonValue(val key: String) : ReadWriteProperty<TippyOptions, Any?> {
        override fun getValue(thisRef: TippyOptions, property: KProperty<*>): Any? = thisRef.json[key]

        override fun setValue(thisRef: TippyOptions, property: KProperty<*>, value: Any?) {
            thisRef.json[key] = value
        }

    }


}

fun tippyOptions(block: TippyOptions.() -> Unit): Json {
    return TippyOptions().apply(block).json
}

private fun getTippyOptions(content: HTMLElement) = json(
    "interactive" to true,
    "trigger" to "click",
    "theme" to "light-border",
    //  "placement" to bidirectional("right", "left"),
    "arrow" to "large",
    "content" to content
)