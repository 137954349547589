package com.yektasarioglu.personal.web.common.extensions

fun String.isValidHttpScheme() = this.startsWith("http") || this.startsWith("https")

fun String.kebabCase() = this.replace(" ", "-")

fun String.lowerKebabCase() = this.lowercase().kebabCase()

fun String.tokenize(joinWithSeparator: String = "-"): String {
    val regex = Regex("\\b\\w+\\b")
    val words = regex.findAll(this)
        .map { it.value.lowercase() }
        .toList()
    return words.joinToString(joinWithSeparator)
}

fun String.capitalize() = replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }

fun String.detokenize(separator: String = "-") = this.replace(separator, " ").capitalize()

fun String.addPrefix(prefix: String, ignoreEmpty: Boolean = true): String {
    if(this.isEmpty() && ignoreEmpty) return this
    else if(this.startsWith(prefix)) return this
    return "$prefix$this"
}

fun String.addSuffix(suffix: String, ignoreEmpty: Boolean = true, allowDuplicateSuffix: Boolean = true): String {
    if(this.isEmpty() && ignoreEmpty) return this
    else if(this.endsWith(suffix) && allowDuplicateSuffix) return this
    return "$this$suffix"
}

fun String.addSurrounding(prefix: String, suffix: String, ignoreEmpty: Boolean = true): String {
    if(this.isEmpty() && ignoreEmpty) return this
    else if(this.startsWith(prefix) && this.endsWith(suffix)) return this
    return "$prefix$this$suffix"
}