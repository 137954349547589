package com.yektasarioglu.personal.web.common.logger

//import co.touchlab.kermit.BaseLogger
//import co.touchlab.kermit.loggerConfigInit
//import co.touchlab.kermit.platformLogWriter
//import co.touchlab.kermit.Severity
import js.process

//object JSLogger : BaseLogger(loggerConfigInit(platformLogWriter())) {
object JSLogger : Logger() {

    //private val isDebugMode: Boolean = process.env.IS_DEBUG.toBoolean()
    override val isDebugMode: Boolean = process.env.IS_DEBUG.toBoolean()
}

// this.javaClass.name

/*
actual val Any.Log: BaseLogger
    get() {
        val logger = JSLogger
        //logger.setTag(tag = this::class.simpleName ?: "")
        JSLogger.setTag(tag = this::class.js.name ?: "")
        return logger
    }*/
actual val Any.Log: Logger
    get() {
        val logger = JSLogger
        //logger.setTag(tag = this::class.simpleName ?: "")
        JSLogger.setTag(tag = this::class.js.name ?: "")
        return logger
    }