package com.yektasarioglu.personal.web.interop

import org.w3c.dom.HTMLElement
import org.w3c.dom.NodeList
import kotlin.js.Json
import kotlin.js.json

//initMediumZoom() {
//    mediumZoom(document.querySelectorAll('main img'), {
//            margin: 160,
//            background: '#000000'
//    })
//}

//external fun mediumZoom(selectors: String, options: Json)
external fun mediumZoom(selectors: HTMLElement, options: dynamic)

@JsModule("medium-zoom/dist/medium-zoom.js")
@JsNonModule
external fun mediumZoom(selectors: NodeList, options: dynamic)

external fun mediumZoom(selectors: NodeList, optionsJson: Json)

fun mediumZoom(selectors: NodeList, options: MediumZoomOptions) =
    mediumZoom(selectors, options = options.toJson())

data class MediumZoomOptions(
    val margin: Int = 0,
    val background: String = "#fff",
    val scrollOffset: Int = 40
//    val container: HTMLElement? = null,
//    val template: HTMLTemplateElement? = null
) {
    fun toJson() = json(
        "scrollOffset" to scrollOffset,
        "background" to background,
        "margin" to margin
    )

}

//external interface MediumZoomOptions {
//    val margin: Int
//    val background: String
//}