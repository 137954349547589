package com.yektasarioglu.personal.web

import com.yektasarioglu.personal.web.behaviour.DynamicHeader
import com.yektasarioglu.personal.web.behaviour.HighlightActiveTOCHeader
import com.yektasarioglu.personal.web.common.logger.JSLogger
import com.yektasarioglu.personal.web.common.logger.Log
import com.yektasarioglu.personal.web.fragment.composable.cookieConsentEntrypointComposable
import com.yektasarioglu.personal.web.interop.HighlightJs
import com.yektasarioglu.personal.web.interop.MediumZoomOptions
import com.yektasarioglu.personal.web.interop.mediumZoom
import com.yektasarioglu.personal.web.interop.tippy
import com.yektasarioglu.personal.web.utils.asElementList
import js.process
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.w3c.dom.Element
import org.w3c.dom.asList
import org.w3c.dom.events.Event
import org.w3c.dom.get
import kotlin.js.json

suspend fun main() = coroutineScope {
    document.addEventListener("DOMContentLoaded", {
        Log.d("isDebug is ${process.env.IS_DEBUG}")
        JSLogger.setTag("main()")
        JSLogger.i("Starting example application...")


        val dynamicHeader = DynamicHeader()
        val highlightActiveTOCHeader = HighlightActiveTOCHeader()


        /*startKoin {
            modules(appModule, navigationModule, remoteModule)
        }

        Application().start()*/

        Log.d("window.location.href is ${window.location.href}")

        checkCookieConsent()

        when {
            window.location.href.contains("/blog") -> {
                dynamicHeader.enable(id = "nav-header")
                highlightActiveTOCHeader.enable()
                initHLJS()
                initMediumZoom()
                initCopyCode(scope = CoroutineScope(Job()))
                initTippy()
                registerDropdownClickListener()

                document.addEventListener("click", { event: Event ->
                    CoroutineScope(Job()).launch {
                        delay(100)
                        println("window.location.hash is ${window.location.hash}")
                        dynamicHeader.disable(id = "nav-header")
                        delay(100)
                        dynamicHeader.enable(id = "nav-header")
                    }
                })

                var lastScrollTop = 0.0
                document.addEventListener("scroll", { // or window.addEventListener("scroll"....
                    //var st = window.pageYOffset || document.documentElement.scrollTop
                    val scrollTop = window.pageYOffset

                    if (scrollTop > lastScrollTop) {
                        // downscroll code
                    } else if (scrollTop < lastScrollTop) {
                        // upscroll code
                    } // else was horizontal scroll

                    lastScrollTop = if (scrollTop <= 0) { 0.0 } else scrollTop // For Mobile or negative scrolling
                }, false)
            }

            window.location.href.contains("/about") -> {
                val profileElement = document.getElementsByClassName("profile")[0]
                profileElement?.addEventListener("mouseover", { disableGrayscale() })
                profileElement?.addEventListener("mouseout", { enableGrayscale() })
            }

        }
    })
}

private fun registerDropdownClickListener() {
    document.addEventListener("click", { event: Event ->
        println("registerDropdownClickListener - e.target is ${event.target}")

        val element = event.target as? Element ?: return@addEventListener
        println("registerDropdownClickListener - element is ${element.innerHTML}")

        val isDropdownButton = element.matches("[data-dropdown-button]")
        println("registerDropdownClickListener - isDropdownButton is $isDropdownButton")

        if (!isDropdownButton && element.closest("[data-dropdown]") != null) return@addEventListener

        var currentDropdown: Element? = null
        if (isDropdownButton) {
            currentDropdown = element.closest("[data-dropdown]")
            println("registerDropdownClickListener - currentDropdown is ${currentDropdown?.innerHTML}")
            currentDropdown?.classList?.toggle("active")
        }

        document.querySelectorAll("[data-dropdown].active")
            .asElementList()
            .forEach { dropdown ->
                if (dropdown === currentDropdown) return@addEventListener
                dropdown.classList.remove("active")
            }
    })
}

private fun initTippy() {
    tippy("[data-tippy-content]")
    //tippy("[data-tippy-content]", json("placement" to "bottom"))
    tippy("#copy-link-button", json("hideOnClick" to false))
}

private fun initCopyCode(scope: CoroutineScope) {
    val copyCodeButtonId = "copy-code-button"

    if (document.getElementById(copyCodeButtonId) == null) {
        val codeElementParent = document.getElementsByTagName("code")

        codeElementParent.asList().forEach { element ->
            //Log.d("initCopyCode() - element.textContent is ${element.textContent}")
            //Log.d("Value is " + element.parentElement?.nodeName)

            if (element.parentElement?.nodeName?.lowercase() == "pre") {
                //Log.d("initCopyCode() - element.parentElement?.textContent is ${element.parentElement?.textContent}")
                val code = element.parentElement?.textContent ?: ""
                val copyText = "copy"
                val copiedText = "copied ✅"
                val copyTextTimeout = 500L

                val btn = document.createElement("button")
                btn.id = copyCodeButtonId
                btn.innerHTML = "Save"
                btn.textContent = copyText
                btn.addEventListener("click", {
                    println("initCopyCode - click")
                    println("initCopyCode - scope is $scope")
                    println("initCopyCode - scope.coroutineContext is ${scope.coroutineContext}")

                    scope.launch {
                        try {
                            println("initCopyCode - click -> START")
                            window.navigator.clipboard.writeText(code)
                            btn.textContent = copiedText
                            delay(copyTextTimeout)
                            btn.textContent = copyText
                            println("initCopyCode - click -> END")
                        } catch (e: Exception) {
                            println("initCopyCode - exception is $e")
                        }
                    }
                })
                element.appendChild(btn)

                val spanList = element.getElementsByTagName("span")
                spanList.asList().forEach { childElement ->
                    //Log.d(childElement.textContent ?: "")
                }
            }
        }
    }
}

private fun checkCookieConsent() {
    val isConsentExists = localStorage.getItem("consentMode") != null
    if (!isConsentExists) {
        //cookieConsentEntrypoint()
        cookieConsentEntrypointComposable()
    }
}

private fun initHLJS() = HighlightJs.highlightAll()

private fun initMediumZoom() {
    mediumZoom(
        selectors = document.querySelectorAll("main .zoomable"),
        options = MediumZoomOptions(margin = 80, background = "#000000")
    )
}

private fun disableGrayscale() {
    val profile = document.getElementsByClassName("profile")[0]
    profile?.setAttribute("style", "-webkit-filter:grayscale(0%)")
}

private fun enableGrayscale() {
    val profile = document.getElementsByClassName("profile")[0]
    profile?.setAttribute("style", "-webkit-filter:grayscale(100%)")
}

external fun gtag(vararg arguments: dynamic)