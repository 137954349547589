package com.yektasarioglu.personal.web.interop

import kotlinext.js.require
import org.w3c.dom.HTMLElement

@JsName("hljs")
@JsModule("highlight.js")
@JsNonModule
private external class HighlightJsBridge {
    companion object {
        fun highlightAll()
        fun highlightElement(block: HTMLElement)
    }
}

object HighlightJs {

    fun highlightAll() {
        //importCSS()
        HighlightJsBridge.highlightAll()
    }

    fun highlightElement(block: HTMLElement) {
        //importCSS()
        HighlightJsBridge.highlightElement(block)
    }

    // highlight.js
    // <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/highlight.js/11.7.0/styles/default.min.css">
    // <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/highlight.js/11.7.0/styles/xcode.min.css">
    // <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/highlight.js/11.7.0/styles/idea.min.css">
    // <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/highlight.js/11.7.0/styles/github-dark-dimmed.min.css">
    // <link rel="stylesheet" href="/assets/css/highlight-js-theme/xcode-dark-mode.css">
    private fun importCSS() {
        //require("highlight.js/styles/default.css")
        //require("./assets/css/highlight-js-theme/xcode-dark-mode.css")
        require("./css/highlight-js-theme/xcode-dark-mode.css")
    }
}