package com.yektasarioglu.personal.web.ui.component

import androidx.compose.runtime.Composable
import com.yektasarioglu.personal.web.ui.designsystem.AppTheme
import com.yektasarioglu.personal.web.ui.designsystem.foundation.SpacingElement
import org.jetbrains.compose.web.css.StyleScope
import org.jetbrains.compose.web.dom.Div

@Composable
fun Spacer(
    styleBuilder: StyleScope.() -> Unit = {},
    spacing: SpacingElement = AppTheme.theme.styleGuide.spacing.xlarge
) {
    Div(attrs = {
        style {
            property("margin-top", "var(${spacing.value})")
            styleBuilder()
        }
    })
}

@Composable
fun RowSpacer(
    styleBuilder: StyleScope.() -> Unit = {},
    spacing: SpacingElement = AppTheme.theme.styleGuide.spacing.xlarge
) {
    Div(attrs = {
        style {
            property("margin-right", "var(${spacing.value})")
            styleBuilder()
        }
    })
}