package com.yektasarioglu.personal.web.ui.designsystem.foundation

import com.yektasarioglu.personal.web.ui.designsystem.cssVariable

data class SpacingElement(
    val name: String,
    val value: String
)

data class Spacing(
    val xxsmall: SpacingElement,
    val xsmall: SpacingElement,
    val small: SpacingElement,
    val medium: SpacingElement,
    val large: SpacingElement,
    val xlarge: SpacingElement,
    val xxlarge: SpacingElement
): DesignElement<SpacingElement> {

    override val sections: List<Pair<String, List<SpacingElement>>> = emptyList()

    override val all = listOf(xsmall, small, medium, large, xlarge, xxlarge)
}

val SpacingElement.cssVariableValue
    get() = cssVariable(this.value)
