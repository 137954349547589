package com.yektasarioglu.personal.web.ui.component.button

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Text

@Composable
fun PrimaryButton(text: String, onClicked: () -> Unit = {}) {
    Button(text, onClicked, "primary-button")
}

@Composable
fun SecondaryButton(text: String, onClicked: () -> Unit = {}) {
    Button(text, onClicked, "secondary-button")
}

@Composable
fun TertiaryButton(text: String, onClicked: () -> Unit = {},) {
    Button(text, onClicked,"tertiary-button")
}

@Composable
fun Button(
    text: String,
    onClicked: () -> Unit = {},
    vararg classes: String = arrayOf()
) {
    val classList = mutableListOf("button")

    if (classes.isNotEmpty()) classList.addAll(classes)

    org.jetbrains.compose.web.dom.Button(attrs = {
        classes(classList)
        onClick { onClicked() }
    }) {
        Text(text)
    }
}