package com.yektasarioglu.personal.web.ui.designsystem

import com.yektasarioglu.personal.web.ui.designsystem.foundation.Colors
import com.yektasarioglu.personal.web.ui.designsystem.foundation.Iconography
import com.yektasarioglu.personal.web.ui.designsystem.foundation.Spacing
import com.yektasarioglu.personal.web.ui.designsystem.foundation.Style
import com.yektasarioglu.personal.web.ui.designsystem.foundation.Typography

// Elevation
// Shadows
// Illustrations
// Logo //Logos & Branding
class StyleGuide(
    val colors: Colors, // ColorPalette
    val iconography: Iconography = Iconography,
    val typography: Typography,
    val styles: List<Style> = listOf(),
    val spacing: Spacing,
) {
    val all = listOf(colors, typography, spacing)
}

// Pattern Library or Component Library
// While component libraries typically contain the individual UI components,
// pattern libraries contain so-called patterns that are composed of several UI components
