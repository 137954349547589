package com.yektasarioglu.personal.web.ui.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.yektasarioglu.personal.web.ui.component.button.PrimaryButton
import com.yektasarioglu.personal.web.ui.component.button.SecondaryButton
import com.yektasarioglu.personal.web.ui.component.button.TertiaryButton
import com.yektasarioglu.personal.web.ui.compose.html.U
import com.yektasarioglu.personal.web.ui.compose.tailwind.foundation.layout.Arrangement
import com.yektasarioglu.personal.web.ui.compose.tailwind.foundation.layout.Column
import com.yektasarioglu.personal.web.ui.compose.tailwind.foundation.layout.Row
import com.yektasarioglu.personal.web.ui.designsystem.AppTheme
import com.yektasarioglu.personal.web.ui.designsystem.LocalTheme
import com.yektasarioglu.personal.web.ui.designsystem.foundation.cssVariableValue
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

data class CheckBoxConsent(
    val name: String,
    val isInitiallyChecked: Boolean,
    val onChecked: (Boolean) -> Unit
)

@Composable
fun CookieConsentBanner(
    checkBoxList: List<CheckBoxConsent>,
    onAcceptAllClicked: () -> Unit = {},
    onAcceptSelectionClicked: () -> Unit = {},
    onRejectAllClicked: () -> Unit = {}
) {
    val colors = LocalTheme.current.styleGuide.colors

    Div(attrs = {
        id("cookie-consent-banner")
        classes("fixed", "bottom-0", "w-full", "lg:right-0", "lg:w-27.5")
        style {
            property("background-color", colors.backgroundColor.cssVariableValue)
        }
    }) {
        Column(classSelectors = listOf("m-6")) {
            H3 { Text("🍪 Cookie settings") }
            Spacer(spacing = AppTheme.theme.styleGuide.spacing.xsmall)
            Div(attrs = { id("cookie-consent-description") }) {
                P {
                    Text("We use cookies to provide you with the best possible experience. They also allow us to analyze user behavior in order to constantly improve the website for you. ")
                    A(href = "/policies/privacy-policy") { U { Text("Privacy Policy") } }
                }
            }
            Spacer(spacing = AppTheme.theme.styleGuide.spacing.small)
            Row(id = "cookie-consent-options", horizontalArrangement = Arrangement.spacedBy(1.0)) {
                checkBoxList.forEach {
                    CheckBox(
                        labelText = it.name,
                        isInitiallyChecked = it.isInitiallyChecked,
                        onChecked = { isChecked -> it.onChecked(isChecked) }
                    )
                }
            }
            Spacer(spacing = AppTheme.theme.styleGuide.spacing.medium)
            Row(horizontalArrangement = Arrangement.spacedBy(1.0)) {
                PrimaryButton(text = "Accept All", onClicked = onAcceptAllClicked)
                SecondaryButton(text = "Accept Selection", onClicked = onAcceptSelectionClicked)
                TertiaryButton(text = "Reject All", onClicked = onRejectAllClicked)
            }
        }
    }
}

@Composable
private fun CheckBox(labelText: String, isInitiallyChecked: Boolean = false, onChecked: (Boolean) -> Unit = {}) {
    val isChecked = remember { mutableStateOf(isInitiallyChecked) }
    Div(attrs = { classes("cookie-consent-checkbox") }) {
        Input(type = InputType.Checkbox) {
            checked(isChecked.value)
            onClick {
                isChecked.value = !isChecked.value
                onChecked(isChecked.value)
            }
            style {
                property("margin-right", "0.3rem")
            }
        }
        Label {
            Text(labelText)
        }
    }
}