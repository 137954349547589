package com.yektasarioglu.personal.web.ui.compose.tailwind.foundation.layout

typealias Rem = Double
//typealias Rem = Float
//typealias Rem = Long

object Arrangement {

    interface Horizontal {
        /**
         * Spacing that should be added between any two adjacent layout children.
         */
        val spacing get() = "0rem"
    }

    interface Vertical {
        /**
         * Spacing that should be added between any two adjacent layout children.
         */
        val spacing get() = "0rem"
    }

    interface HorizontalOrVertical {
        /**
         * Spacing that should be added between any two adjacent layout children.
         */
        val spacing get() = "0rem"
    }

    fun spacedBy(space: Rem) = object: HorizontalOrVertical {
        override val spacing: String
            get() = "${space}rem"
    }
}