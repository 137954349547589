package com.yektasarioglu.personal.web.utils

import kotlinext.js.asJsObject
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.ItemArrayLike

fun getRelativeOffsetToWindow(element: Element): Pair<Double, Double> {
    val rect = element.getBoundingClientRect()
    return rect.left + window.scrollX to rect.top + window.scrollY
}

fun getY(element: Element): Double {
    return element.getBoundingClientRect().y
}

fun htmlElementByIdOnChange(id: String, listener: (HTMLElement) -> Unit) {
    document.addEventListener("DOMSubtreeModified", {
        (it.target as? HTMLElement)?.let { htmlElement ->
            println("DOMSubtreeModified - it is $it")
            println("DOMSubtreeModified - it.target is ${htmlElement}")
            println("DOMSubtreeModified - it.id is ${htmlElement.id}")
            println("DOMSubtreeModified - it.currentTarget.id is ${(it.currentTarget as? HTMLElement)?.id}")

            if (htmlElement.id == id) listener(htmlElement)
        }
    })
}

fun htmlElementByElementOnChange(element: String, listener: (HTMLElement) -> Unit) {
    document.addEventListener("DOMSubtreeModified", {
        (it.target as? HTMLElement)?.let { htmlElement ->
            println("DOMSubtreeModified - it is $it")
            println("DOMSubtreeModified - it.target is ${htmlElement}")
            println("DOMSubtreeModified - it.id is ${htmlElement.id}")
            println("DOMSubtreeModified - it.currentTarget.id is ${(it.currentTarget as? HTMLElement)?.id}")

            val element = htmlElement.closest(element)

            if (element != null) listener(htmlElement)
        }
    })
}

fun htmlElementByIDOnChange(id: String, listener: () -> Unit) {
    document.addEventListener("DOMSubtreeModified", {
        (it.target as? HTMLElement)?.let { htmlElement ->
            println("DOMSubtreeModified - it is $it")
            println("DOMSubtreeModified - it.target is ${htmlElement}")
            println("DOMSubtreeModified - it.currentTarget is ${it.currentTarget?.asJsObject()}")

            if (document.contains(document.getElementById(id))) listener()
        }
    })
}

fun <T> ItemArrayLike<T>.asElementList(): List<Element> = object : AbstractList<Element>() {
    override val size: Int get() = this@asElementList.length

    override fun get(index: Int): Element = when (index) {
        in 0..lastIndex -> this@asElementList.item(index).unsafeCast<Element>()
        else -> throw IndexOutOfBoundsException("index $index is not in range [0..$lastIndex]")
    }
}

fun <T> ItemArrayLike<T>.asHTMLElementList(): List<HTMLElement> = object : AbstractList<HTMLElement>() {
    override val size: Int get() = this@asHTMLElementList.length

    override fun get(index: Int): HTMLElement = when (index) {
        in 0..lastIndex -> this@asHTMLElementList.item(index).unsafeCast<HTMLElement>()
        else -> throw IndexOutOfBoundsException("index $index is not in range [0..$lastIndex]")
    }
}